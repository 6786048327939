import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import { authStore } from '@/stores/auth'
import { notification } from 'ant-design-vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Login',
    component: () => import(/* webpackChunkName: "login" */ '../views/Login.vue')
  },
  {
    path: '/wachtwoord-vergeten',
    name: 'ForgotPassword',
    component: () => import(/* webpackChunkName: "forgotPassword" */ '../views/ForgotPassword.vue')
  },
  {
    path: '/wachtwoord-wijzigen',
    name: 'ChangePassword',
    component: () => import(/* webpackChunkName: "changePassword" */ '../views/ChangePassword.vue')
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: () => import(/* webpackChunkName: "dashboard" */ '../views/Dashboard.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/documenten',
    name: 'Documents',
    component: () => import(/* webpackChunkName: "documents" */ '../views/Documents.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/account',
    name: 'Account',
    component: () => import(/* webpackChunkName: "account" */ '../views/account/Account.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/servicemelding/toevoegen',
    name: 'IssueCreate',
    component: () => import(/* webpackChunkName: "issueCreate" */ '../views/issues/IssueCreate.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/servicemelding/:issueId',
    name: 'IssueShow',
    component: () => import(/* webpackChunkName: "issueShow" */ '../views/issues/IssueShow.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/vraag/toevoegen',
    name: 'QuestionCreate',
    component: () => import(/* webpackChunkName: "questionCreate" */ '../views/questions/QuestionCreate.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/vraag/:questionId',
    name: 'QuestionShow',
    component: () => import(/* webpackChunkName: "questionShow" */ '../views/questions/QuestionShow.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/nieuws/:newsId',
    name: 'NewsShow',
    component: () => import(/* webpackChunkName: "newsShow" */ '../views/news/NewsShow.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/bericht/:messageId',
    name: 'MessageShow',
    component: () => import(/* webpackChunkName: "messageShow" */ '../views/message/MessageShow.vue'),
    meta: {
      requiresAuth: true
    }
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  const auth = authStore()
  window.scrollTo(0, 0)
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (auth.loggedIn) {
      // If a permission is needed.
      if (to.meta?.permission) {
        // Check if user has the right permissions.
        if (auth.user.permissions.includes(to.meta?.permission)) {
          next()
          return
        }
        notification.warning({ message: 'Toegang geweigerd!', description: 'U heeft niet de juiste rechten voor dit onderdeel.' })
        return
      } else {
        next()
        return
      }
    }
    next('/')
  } else {
    next()
  }
})

export default router
